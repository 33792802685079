button.close {
    font-size: 38px !important;
    margin-top: -5px !important;
    color: #ff1f1f !important;
    border: 0;
    padding : 10px;
    padding-bottom: 5px !important;
    padding-top: 0px !important;
    height: 40px;
    line-height: 1px;
}

