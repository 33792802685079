.home{
    height: 100%;
}

.d-flex {
    display: -ms-flexbox!important;
    display: flex!important;
}

.home .content-wrapper{
    flex:auto;
}