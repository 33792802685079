.side-bar{
    /* position: fixed; */
    min-width: 70px;
    top:0; 
    left:0;
    bottom:0;
    background-color: #222222;
    padding: 10px;  
    display: flex;
    flex-direction: column;
    z-index: 1001;
    /* box-shadow: 3px 0 15px #e7e7e7; */
}

.top{
    flex-grow: 5;
    align-content: flex-start;
}

.bottom{
    flex-grow: 0;
    align-content: flex-end;
}

.center{
    flex-grow: 6;
    align-content: center;
}

.main-icons{
    margin-left: 5%;
    margin-right: 5%;   
}
.main-icons svg{
    fill: #0275d8;
    -webkit-transition-property: fill; /* Safari */
    -webkit-transition-duration: 0.5s; /* Safari */
    transition-property: fill;
    transition-duration: 0.5s;
}

/* .main-icons:hover svg, .main-icons:focus svg, .main-icons:active svg{
    fill:#0275d8;
} */


.fa-2x {
    margin: 0 auto;
    float: none;
    display: table;
    color: #212121;
}

.sidebar-icons{
    text-decoration: none !important;
    fill:#ffffff;
}

.sidebar-icons:hover{
    text-decoration: none !important;
    fill: #0275d8;
}

.btn-logout,.btn-logout:focus,.btn-logout:active{
    background-color: inherit;
    border:none;
    outline: none !important;
}

.btn-logout:hover{
    /* background-color: #00000000 !important; */
    background-color: inherit;
    fill: #0275d8;
    outline: none !important;
}