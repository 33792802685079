*:focus {
    outline: none !important;
}


.searchInput{
    margin-top: 1%;
    color: #212121;
    background-color: #00000000;
    border : 0px;
    border-bottom: 2px solid #d3d3d3;
}
.search-pre-sec{
    font-weight: 500;
    border: 0;
}

.search-icon-button{
    margin-left: 10px;
    margin-right: 10px;
}

.bg-light{
    background-color: #fff;
}

.card-rewards{
    padding: 0%;
    margin: 7px;
    box-shadow: 0px 0px 14px #c5c5c5;
    width: 30%;
}

.card-rewards-body {
    padding: 0;
}

.user-page{
    width: 95%;
    margin-top: 4%;
}

.btn-default {
    color: #333;
}