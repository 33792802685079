.reward-input{
    display: block;
      width: 100%;
      height: calc(2.25rem + 10px);
      padding: .375rem .75rem;
      font-size: 1.6rem;
      line-height: 2;
      color: #212121;
      background-color: #00000000;
      background-clip: padding-box;
      border: 0px;
      border-bottom: 2px solid #acacac;
      border-radius: 0;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  
  .reward-input:focus{
    color: #212121;
    background-color: #00000000;
    border-color: #212121;
    outline: 0;
    box-shadow: 0 0 0 0.0rem rgba(0,123,255,.25);
  }

  .coupon-enter-code-text{
    font-size: 1.6rem;
    font-weight: 800;
    margin-top:2%;
    margin-left: 50%;
  }