
@import url('https://fonts.googleapis.com/css?family=Quicksand');
html{
    height: 100%;
}
body{
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    height: 100%;
}

#root,#app,#index-root{
    height: inherit;
}

.panel{
    border: none;
}

.form-control {
    margin-top: 1%;
    font-size: 1.6rem;
    color: #212121;
    background-color: #00000000;
    border : 0px;
    border-bottom: 1px solid #a5a5a5;
    box-shadow: 0 0 0 0rem rgba(0,123,255,.25);
    border-radius: 0;
}

.form-control:focus, .form-control:hover, .form-control:active {
    color: #212121;
    background-color: #00000000;
    border-color: rgb(0, 153, 255) !important;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(0,123,255,.25);
    border-radius: 0;
}


.btn{
    border-radius: 0px;
}

.custom-margin-top{
    margin-top:10%;
}


/* remove these when Bootstrap is upgraded to 4.0.0 */

.img-fluid{
    width: 100%;
}

.height-inherit{
    height: inherit;
}