.reward .reward-image{
    padding: 10px 5px;
}

.reward{
    background-color: #ffffff;
    overflow: hidden;
}
.redeem-section .redeem-button{
    border-radius: 0 !important;
}
.row-centered {
    text-align:center;
}
.col-centered {
    display:inline-block;
    float:none;
    /* reset the text-align */
    text-align:left;
    /* inline-block space fix */
    margin-right:-4px;
}