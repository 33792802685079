.leader-board [class^='lead-']{
    font-size: 28px;
}
.leader-board .lead-0{
    color:#FFD700
}
.leader-board .lead-1{
    color: silver;
}
.leader-board .lead-2{
    color:#CD7F32
}
.leader-board .leader-content{
    padding: 10px 0;
}