.card {
    border: 0;
}

/* Login Page Custom Inputs */

.login {
    display: block;
    width: 100%;
    height: calc(1.8rem + 10px);
    padding: .275rem .75rem;
    font-size: 1.53rem;
    line-height: 2;
    color: #212121;
    background-color: #00000000;
    background-clip: padding-box;
    border: 0px;
    border-bottom: 2px solid #494949;
    border-radius: 0;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.login:focus, .login:active, .login:hover {
    color: #212121;
    background-color: #00000000;
    border-color: #212121;
    outline: 0;
    box-shadow: 0 0 0 0.0rem rgba(0, 123, 255, 0.849);
}