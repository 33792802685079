.account-container{
    height: inherit;
}

.account-sub-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: inherit;
}
